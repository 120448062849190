import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import { IconButton } from "@mui/material";
import logo from "./HRI_Company_logo.png";
import profile from "./profile.png";
import { NavLink } from "react-router-dom";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "./styles.css";

// import required modules
import { RiDashboardFill, RiProfileFill } from "react-icons/ri";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { BiLogOut } from "react-icons/bi";
import { hiding, selectHeader, showing } from "../features/HeaderSlice";
import { HiOutlineMenu } from "react-icons/hi";
import { MdOutlineClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { FaGraduationCap, FaIdCard } from 'react-icons/fa'
import { BsCalendar2Event } from 'react-icons/bs'
import { Drawer, Space } from "antd";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import axios from "axios";
import { toast } from "react-toastify";



const cookies = new Cookies();

const Header_Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([])
  const [slide, setSlide] = useState([])
  const currentState = useSelector(selectHeader);



  const [nav, setNav] = useState(true);
  const [active, setActive] = useState("Posted Jobs")
  const [visible1, setVisible1] = useState(false);
  const [placement, setPlacement] = useState("left");


  const showDrawer = () => {
    setVisible1(true);
  };

  const onClose = () => {
    setVisible1(false);
  };

function logOut () {
  onClose();
  navigate("/");
  axios.post("auth/user/logout",{id : `${cookies.get("id")}`},{
    headers : {
      Autorization: "Token " + cookies.get("cokieToken")
    }
  }).then( res=>{
    toast.success(res.data.message, {
      position: "top-center"
    })
    cookies.remove("cookieToken")
    cookies.remove("id")
    cookies.remove("email")
  }).catch( err => {
    toast.warning(err.message, {
      position: "top-center"
    })
  })
}

  return (
    <div className="relative">
      <div className="z-10 bg-white flex justify-between drop-shadow rounded-lg fixed-top w-full top-0">
        <div className=" md:space-x-1  mx-4 md:mx-5 flex  ">
          <div className="header_nav_icon_div my-4 cursor-pointer ">
            {/* {currentState?.show === true ? (
              <IconButton>
                {" "}
                <CloseIcon onClick={showDrawer} className="cursor-pointer" />
              </IconButton>
            ) : ( */}
            <IconButton>
              {" "}
              <MenuIcon onClick={showDrawer} className="cursor-pointer" />
            </IconButton>
            {/* )} */}
          </div>

          <div className="logo-container w-full flex mt-3 md:mt-2 md:space-x-2 text-sm sm:text-md md:text-xl lg:text-xl px-2">
            <div>
              <img
                src={logo}
                alt=""
                className="h-7 md:h-9 lg:h-10   p-[2px] rounded-full"
              />
            </div>
            <p className="font-semibold text-xs md:text-lg text-blue-700  mt-3 ">
              HR Intelligent Company
            </p>
          </div>
        </div>
        <div className="flex space-x-4 justify-center">
          <div>

            <div className="dropdown relative">

              <button
                onClick={() => navigate("/CompanyProfile")}
                className="  dropdown-toggle font-medium text-xs leading-tight uppercase rounded transition duration-150 ease-in-out flex items-center whitespace-nowrap "
                type="button"
                aria-expanded="false"
              >
                <div>
                  <div className="space-x-3">
                    <div className="space-x-2 flex justify-end mt-3  w-full">
                      <div className="">
                        {" "}
                        <Avatar
                          src={`${cookies.get("profile")}`}
                          className="border-2 border-slate-500"
                        />
                      </div>
                      <div className="flex">
                        <div>
                          {" "}
                          <div className="text-xs font-semibold   md:text-base lg:text-lg w-full mt-2 mr-6">
                            {cookies.get("companyName")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* ******************************** */}

      <Drawer
        title={<div className="text-[#0865B6] font-semibold">HR Intelligence Company</div>}
        placement={placement}
        closable={false}
        onClose={onClose}
        visible={visible1}
        key={placement}
        width={280}
        extra={
          <Space>
            {/* <Button onClick={onClose}>Cancel</Button> */}
            <IconButton onClick={onClose}>
              <ArrowBackIosNewIcon className="arrow_icon" />
            </IconButton>
          </Space>
        }
      >
        <div className="flex  flex-col justify-between sidebar_options_div hover:rounded text-sm text-gray-400 space-y-1 mx-3 ">
          <div className="space-y-2 mb-6">
            <div
              onClick={() => navigate("/CompanyProfile")}
              className={`hover:bg-neutral-100 rounded cursor-pointer flex items-centercursor-pointer hover:text-sky-700 p-2 font-semibold 
                            ${window.location.pathname === "/CompanyProfile" ? "bg-neutral-100 text-sky-700" : ""}`}
            >
              <p className="m-1 flex font-bold text-lg">
                <RiProfileFill className="mr-4 text-2xl my-auto" />
                Company Profile
              </p>
            </div>
            <div
              onClick={() => navigate("/Colleges")}
              className={`hover:bg-neutral-100 rounded flex cursor-pointer items-centercursor-pointer hover:text-sky-700 p-2 font-semibold 
                            ${window.location.pathname === "/Colleges" ? "bg-neutral-100 text-sky-700" : ""}`}
            >
              <p className="m-1 flex font-bold text-lg ">
                <FaGraduationCap className="mr-4 text-2xl my-auto" />
                Colleges
              </p>
            </div>

          </div>
          <hr className="bg-gray-300 h-[1px]" />
            <div onClick={logOut}
              className="single_option_div font-bold text-lg hover:bg-neutral-100 w-[220px] p-3 cursor-pointer flex space-x-2 hover:rounded hover:text-sky-700"
            >
              <BiLogOut className="text-2xl option_icon my-auto" />
              <div className="option_title">  
                <div className="single_option_anchor">
                  Logout
                </div>
              </div>
            </div>
        </div>


      </Drawer>

    </div>
  );
};

export default Header_Navbar;
