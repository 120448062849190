import LogImg from "./LoginImg.png";
import React, { useState } from "react";


import { FcGoogle } from "react-icons/fc";
import { BsFacebook } from "react-icons/bs";
import { BsApple } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import axios from "axios";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { toast } from "react-toastify";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField
} from "@mui/material";
import "./Login.css"
import { Help, Info } from "@mui/icons-material";
import { message } from "antd";

const cookies = new Cookies();

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [error, setError] = useState('')

  function Login(e) {
    e.preventDefault();
    let data = { email, password };
    if(email.length < 4 || password.length < 5){
      return message.info("Please fill the correct details")
    }
    axios
      .post("auth/user/login", data)
      .then((resp) => {
        console.log(resp);
        navigate(`/Colleges`);
        message.success("Login successfully!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        cookies.set("cokieToken", resp.data.user.token);
        // localStorage.set("token1", resp.data.user.token);
        cookies.set("id", resp.data.user.id);
        cookies.set("email", resp.data.email);
        cookies.set("profile", resp.data.user.profile?.image);
      }).catch((error) => {
      let  err = error.response?.data?.errors
      message.warning(err != undefined ? "A user with this email and password was not found." : error.message);
      console.log({error})
      });
  }
  // toast.configure();
  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });


  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };





  return (
    <>
      <div className="h-[99vh] grid place-items-center">
        <div className="mx-2 md:mx-10">
          <div className=" md:flex ">
            <div className="hidden md:flex md:flex-col  w-1/2">
              <div className="flex md:mt-16 lg:mt-2">
                <div className="md:text-lg lg:text-2xl xl:text-3xl lg:mt-1 font-semibold ">
                  Welcome to
                </div>
                <div className="ml-1  md:text-xl lg:text-3xl xl:text-4xl font-semibold text-blue-700">
                  HRI Mybizmo
                </div>
              </div>
              <div className="text-slate-400 md:text-sm lg:text-lg">
                Plan your next recruitment here
              </div>

              <div className=" overflow-hidden w-[40vw]">
                <img
                  src={LogImg}
                  alt=""
                  className="w-0 md:w-full px-4"
                />
              </div>
              <div className="grid justify-end">
                <div className="mt-2 text-xs lg:text-base text-slate-500">
                  Powered by HRI @MyBizmo
                </div>
              </div>
            </div>
            <div className="border-[3px] lg:pt-14 py-5 px-5  md:w-[40vw] w-[90vw] rounded-lg border-[#0865B6] md:ml-20">
                <div className="grid grid-cols-1 gap-y-4 mt-6">
                  <div className="text-2xl md:text-3xl font-bold">
                  Login as Recruiter
                </div>
                <div className="xl:mt-6">
                  <TextField
                    className="w-full "
                    label="Enter Email ID/ Phone Number"
                    id="outlined-size-small"
                    onChange={(e) => setEmail(e.target.value)}
                    size="small"
                  />
                </div>

                <div className="">
                  <FormControl variant="outlined" className="w-full">
                    <InputLabel htmlFor="outlined-adornment-password" className="">
                      <div className="mt-[-5px]">Enter Your Password</div>
                    </InputLabel>
                    <OutlinedInput
                      label="Current Password"
                      className="w-full"
                      type={values.showPassword ? 'text' : 'password'}
                      onChange={(e) => setPassword(e.target.value)}
                      size="small"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            type={values.showPassword ? 'text' : 'password'}
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="small"
                          >
                            {values.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      required
                    />
                  </FormControl>
                </div>
                <div className="grid justify-end">
                  <Link to="/ForgotPass">
                    <div className="text-sm text-slate-500 cursor-pointer">
                      Forgot Password?
                    </div>
                  </Link>
                </div>
                <div className="mt-2 xl:mt-6">
                  <Button onClick={Login} className="w-full" variant="contained">
                    Login
                  </Button>
                </div>
                <div>
                  <Link to="/Signup">
                  <Button
                      fullWidth
                      variant="outlined"
                      style={{ borderColor: "#0865B6", color: "#0865B6" }}
                    >
                      Not a Member? Sign Up
                    </Button>
                  </Link>
                </div>
                {/* <div className="grid justify-center">
                <div className="text-slate-600 font-semibold text-sm">
                  Or login with
                </div>
              </div> */}
                {/* <div className="hidden">
                <div>
                  <button className="border-2 px-2 py-1 xl:px-5 xl:py-2 xl:text-lg rounded border-slate-400">
                    <FcGoogle />
                  </button>
                </div>
                <div>
                  <button className="text-blue-600 border-2 xl:px-5 xl:py-2 xl:text-lg px-2 py-1 rounded border-slate-400">
                    <BsFacebook />
                  </button>
                </div>
                <div>
                  <button className="text-slate-500 border-2 px-2 xl:px-5 xl:py-2 xl:text-lg py-1 rounded border-slate-400">
                    <BsApple />
                  </button>
                </div>
              </div> */}
                <div className="flex justify-center font-semibold text-xs xl:text-sm text-center">
                  <div>The&nbsp;</div>
                  <div className="text-blue-700">terms of use&nbsp;</div>
                  <div>and&nbsp;</div>
                  <div className="text-blue-700 ">our Policy</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Login;
