import React, { useEffect, useState } from "react";

import { selectHeader } from "../features/HeaderSlice";
import { useSelector } from "react-redux";

import { RiBuildingFill } from "react-icons/ri"
import { useNavigate } from "react-router-dom";
import { GoLocation } from "react-icons/go";
import { FcGoogle } from "react-icons/fc";
import { BsFacebook } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { AiOutlineEdit } from "react-icons/ai";
import axios from "axios";
import Cookies from "universal-cookie";
import { BsFillDiamondFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import comp from "./CompPng.png";

import Header_Navbar from "../NavBar-Sidebar_CompanyList/Header_Navbar";
import { Button } from "antd";
import { PlusCircleOutlined } from '@ant-design/icons';
import { BiUser } from "react-icons/bi";
const cookies = new Cookies();

const CompanyProfile = () => {
  const navigate = useNavigate();
  const [complogo, setCompLogo] = useState("")
  const [data, setData] = useState([])
  const [name, setName] = useState("")
  const [status, setStatus] = useState("")
  const [ceo, setCeo] = useState("")
  const [hr, setHr] = useState("")
  const [established, setEstablished] = useState("")
  const [workingSector, setWorkingSector] = useState("")
  const [founder, setFounder] = useState("")
  const [phone, setPhone] = useState("")
  const [emp, setEmp] = useState("")

  const [address, setAddress] = useState("")
  const [city, setCity] = useState("")
  const [state, setState] = useState("")
  const [country, setCountry] = useState("")
  const [desc, setDesc] = useState("")
  const [compUrl, setCompUrl] = useState("")
  const [facebook, setFacebook] = useState("")
  const [linkedIn, setLinkedIn] = useState("")
  const [complogo1, setCompLogo1] = useState("")


  async function getData() {
    await axios
      .get("company/company-detail-list", {
        headers: {
          Authorization: "Token " + cookies.get("cokieToken"),
        },
      })
      .then((resp) => {
        console.log(resp.data);
        setData(resp.data)
        setName(resp.data.name)
        setCompLogo(resp.data.company_logo)
        setStatus(resp.data.status)
        setCeo(resp.data.company_ceo)
        setHr(resp.data.contact_person)
        setEstablished(resp.data.established_in)
        setWorkingSector(resp.data.working_sector)
        setFounder(resp.data.company_founder)
        setPhone(resp.data.company_number)
        setEmp(resp.data.company_employee)
        setAddress(resp.data.address)
        setCity(resp.data.city)
        setState(resp.data.state)
        setCountry(resp.data.country)
        setDesc(resp.data.company_description)
        setCompUrl(resp.data.company_url)
        setFacebook(resp.data.facebook_url)
        setLinkedIn(resp.data.linkedin_url)
        setCompLogo1(resp.data.logo_url)
        cookies.set("companyName", resp.data.name)
        cookies.set("companyProfile", resp.data.company_logo)


      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  const currentState = useSelector(selectHeader);
  return (
    <>
      <Header_Navbar />
      <div className="pt-[68px]">
        {data.length === 0 ? <div

          className="pt-5 pb-10  bg-gray-100 px-4  md:px-10   w-full"
        >
          <div>
            <div className="bg-white flex justify-center px-8 md:px-12 pb-20 pt-8 w-full rounded-lg h-[500px]">
              <div>
                <RiBuildingFill className="text-8xl text-center  w-full mt-20 text-blue-400" />


                <Button onClick={() => navigate("/CreateCompanyProfile")} icon={<PlusCircleOutlined />} type="primary">
                  Add Company Profile
                </Button>
              </div>


            </div>

          </div>
        </div> : <div

          className="pt-5 pb-10  bg-gray-100 px-4  md:px-10   w-full h-auto"
        >
          <div>
            <div className="bg-white px-8 md:px-12 pb-10 pt-8 rounded-lg">
              <div className="md:flex justify-between">
                <div className=" md:flex justify-center gap-4 md:gap-12">
                  <div className="flex justify-center ">
                    <div className="w-32">
                      <img
                        className="w-32 h-32 mt-2   border-2 border-gray-600 rounded-full "
                        src={complogo ? complogo : comp}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <div className="font-semibold my-auto md:ml-4 text-lg md:text-2xl">
                      <div className="flex gap-2">
                        <BiUser className="mt-1" />{name}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mx-[auto] md:mx-0">
                  <div className="lg:flex mt-2 lg:mt-0 lg:space-x-4 lg:space-y-0 space-y-2">

                    <div
                      className={`${status ? "text-green-600" : "text-gray-400 "
                        } " flex justify-center bg-slate-100 px-4 py-2 rounded-lg font-semibold text-sm"`}
                    >
                      <BsFillDiamondFill className=" mt-1 mr-2" />{" "}
                      {status
                        ? "Actively Hiring"
                        : "In-Actively Hiring"}{" "}
                    </div>
                    <Link to="/CompanyEdit">
                      <div className="flex cursor-pointer mt-2 lg:mt-0 justify-center bg-slate-100 px-4 py-2 rounded-lg font-semibold text-blue-600 ">
                        Edit <AiOutlineEdit className="ml-2  text-xl " />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="text-center md:text-left md:flex justify-evenly mt-5 md:mt-10 text-sm font-semibold md:text-xl">
                <div className="grid gap-y-5">
                  <div className="grid gap-y-2">
                    <div className="text-gray-400">CEO</div>
                    <div>{ceo}</div>
                  </div>
                  <div className="grid gap-y-2">
                    <div className="text-gray-400 ">Human Resources (HR)</div>
                    <div>{hr}</div>
                  </div>
                  <div className="grid gap-y-2">
                    <div className="text-gray-400 ">Established In</div>
                    <div>{established.slice(0, 4)}</div>
                  </div>
                  <div className="grid gap-y-2">
                    <div className="text-gray-400 ">Working Sector</div>
                    <div>{workingSector}</div>
                  </div>
                </div>
                <div className="grid gap-y-5 md:ml-40">
                  <div className="grid gap-y-2">
                    <div className="text-gray-400 ">Founder</div>
                    <div>{founder}</div>
                  </div>
                  <div className="grid gap-y-2">
                    <div className="text-gray-400 ">HR Office contact</div>
                    <div>{phone}</div>
                  </div>
                  <div className="grid gap-y-2">
                    <div className="text-gray-400 ">Employees</div>
                    <div>{emp}</div>
                  </div>
                  <div className="grid gap-y-2">
                    <div className="text-gray-400 ">Location</div>
                    <div className="flex">
                      <GoLocation className="text-gray-400 hidden md:flex text-2xl md:text-xl  mr-2" />{" "}
                      <div className="w-full md:w-2/3 text-center md:text-left">
                        {address} {city}, {state},{" "}
                        {country}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:ml-[12%] mt-5 font-semibold text-sm text-center md:text-left md:text-xl ">
                <div className="text-gray-400 ">Company Description</div>
                <div className="mt-3">
                  {desc}
                </div>
              </div>
              <div className="md:ml-[12%] mt-5 font-semibold text-sm text-center md:text-left">
                <div className="text-gray-400 md:text-xl md:mb-4">Company Social media</div>
                <div
                  className="md:flex lg:w-4/5 xl:w-3/5 mt-3 gap-3 md:gap-10 grid place-items-center"
                >
                  {compUrl ? <div className="flex hover:border-gray-400 transition-all cursor-pointer hover:scale-[1.03] border-2 lg:text-2xl py-2 px-10 rounded-lg ">
                    <FcGoogle className="mr-2 lg:text-3xl text-xl" />
                    {compUrl}
                  </div> : ""}
                  {facebook ? <div className="flex hover:border-gray-400 transition-all cursor-pointer hover:scale-[1.03] lg:text-2xl border-2 py-2 px-10 rounded-lg ">
                    <BsFacebook className="lg:text-3xl text-xl mr-2 text-blue-600" />
                    {facebook}
                  </div> : ""}
                  {linkedIn ? <div className="flex hover:border-gray-400 transition-all cursor-pointer hover:scale-[1.03] lg:text-2xl border-2 py-2 px-10 rounded-lg">
                    {" "}
                    <BsLinkedin className="lg:text-3xl text-xl mr-2 text-blue-600" />{" "}
                    {linkedIn}
                  </div> : ""}
                </div>
              </div>

            </div>

          </div>

        </div>}
      </div>
    </>
  );
};

export default CompanyProfile;
