/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";



import axios from "axios";
import { selectHeader } from "../features/HeaderSlice";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import Header_Navbar from "../NavBar-Sidebar_CompanyList/Header_Navbar";
import { Button, Input } from "antd";
import { BsSearch } from "react-icons/bs";
import { TiLocationOutline } from "react-icons/ti";
import { useSearchParams } from 'react-router-dom';

const cookies = new Cookies();

const Colleges = () => {
  const navigate = useNavigate();
  const currentState = useSelector(selectHeader);
  const [data,setData] = useState([])
  const [input, setInput] = useState("");
  const [output2, setOutput2] = useState([]);
  const [id,setId] = useState()

  const [searchParams] = useSearchParams();
  
  async function getData() {
    await axios
        .get('company/client-list', {
            headers: {
                Authorization: "Token " + cookies.get("cokieToken"),
            },
        })
        .then((resp) => {
            setData(resp.data);

        })
        .catch((err) => {
            console.log(err);
        });
}
useEffect(() => {
getData()
},[,input])
useEffect(() => {
  getData()
  },[])
  return (
    <>
      <Header_Navbar />
      <div className="pt-[68px]">
        <div className="pb-20  bg-gray-100 ">
          <div>
            <div className="bg-white p-2 pt-4 ">
              <div className="md:w-1/2 mx-4">
              <Input
                placeholder="Enter Company Name"
                size="large"
                suffix={<BsSearch className="site-form-item-icon" />}
                onChange={(e) => setInput(e.target.value)}
              />
              </div>
            
            </div>
            <div className="px-4"> 
           
              {
        
              data.map((item) =>{
                return(
                  item?.business_name?.toLowerCase().includes(input?.toLowerCase()) &&(
                    <div key={item.id} className="px-10  bg-white rounded-md mt-4 py-10">
                    <div  className="flex flex-col md:flex-row w-full gap-4 justify-between gap-x-4  md:gap-x-10  ">
                    <div className="flex justify-center items-center h-16">
                      <div className="hover:scale-105 cursor-pointer">
                        <img
                          src={item.logo}
                          className="w-20 rounded-full"
                          title="image_"
                        />
                      </div>
                    </div>
                    <div className="flex justify-center items-center h-16 ">
                      <div>
                        <div>
                          <span className="font-semibold text-xl  md:text-2xl lg:text-3xl ">
                            {item.business_name}
                          </span>{" "}
                        
                        </div>{" "}
                        <div className="flex md:text-lg ">
                          <span>
                            <TiLocationOutline className="mt-1 mr-1" />{" "}
                          </span>{" "}
                          <span className="w-60 md:w-96 truncate">
                           {item.ofc_address}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-center items-center h-16">
                      <div>
                        <Button
                        size="large"
                          onClick={() => { cookies.set('compnyId',item.id); navigate("/CompanyDetails") }}
                          type="primary"
                          style={{borderRadius: "5px"}}
                          // className="bg-blue-500 px-6 py-2 font-semibold text-white rounded-md  "
                        >
                          Details
                        </Button>
                      </div>
                    </div>
                    
                  </div>
                  </div>
                  )
                   
                  )
                
                
              })}
           
        
            </div>
        
          </div>
        </div>
      </div>
    </>
  );
};

export default Colleges;
